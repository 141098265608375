import { FC } from 'react';
import { UpRightArrowIcon } from '~src/components/icon/arrows';
import { DisplayP } from '~src/components/text';
import { SocialEmbedUrlForUI } from '~src/shared-apps/social/types/post/ui';
import { css } from '~src/styled-system/css';
import { Box, styled } from '~src/styled-system/jsx';

export const EmbedUrl: FC<SocialEmbedUrlForUI> = ({
  url,
  ogImage,
  ogTitle,
  ogDescription,
}) => {
  return (
    <>
      <EmbedUrlContainer href={url} target="_blank" rel="noopener noreferrer">
        {/* <ButtonRow
          $paddingType="increment"
          style={{
            flexGrow: 1,
            flexBasis: 0,
            minWidth: 0,
            position: 'relative',
          }}
        > */}
        {!!ogImage && <EmbedUrlImage src={ogImage} />}
        <Box minWidth={'0'} flexGrow={'1'} flexBasis={'0'}>
          <DisplayP.Default textCutoff={'oneLineInFlex'}>
            {ogTitle}
          </DisplayP.Default>
          {!!ogDescription && (
            <DisplayP.Caption
              textCutoff={'oneLineInFlex'}
              // pt={'halfIncrement'}
              color={'gray.400'}
            >
              {ogDescription}
            </DisplayP.Caption>
          )}
        </Box>
        <UpRightArrowIcon
          className={css({
            width: 'icon.eighteen',
            height: 'icon.eighteen',
            color: 'gray.400',
          })}
        />
        {/* </ButtonRow> */}
      </EmbedUrlContainer>
    </>
  );
};

const EmbedUrlContainer = styled('a', {
  base: {
    border: '1px solid',
    borderColor: 'gray.200',
    rounded: 'innerBox',
    textDecoration: 'none',
    p: 'innerBox',
    bg: 'white-pane.75',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    minWidth: '0',
    gap: 'box',
    overflow: 'hidden',
  },
});

const EmbedUrlImage = styled('img', {
  base: {
    width: '[3rem]',
    height: '[3rem]',
    aspectRatio: '1:1',
    borderRadius: 'innerOfInnerBox',
    overflow: 'hidden',
    display: 'block',
  },
});
