import { FC } from 'react';
import type { SocialEmbedImageForUI } from '~src/shared-apps/social/types/post/ui';
import { styled } from '~src/styled-system/jsx';

export type SocialEmbedImageGridProps = {
  type: 'image-grid';
  images: SocialEmbedImageForUI[];
};

export const EmbedImageGrid: FC<SocialEmbedImageGridProps> = ({ images }) => {
  const isSingleImage = images.length === 1;

  if (isSingleImage) {
    const image = images[0];
    return (
      <EmbedImageGridContainer gridTemplateColumns={'1'}>
        <EmbedImageContainer>
          <EmbedImage
            src={image.src}
            alt={image.alt}
            style={{ aspectRatio: image.aspectRatio }}
          />
        </EmbedImageContainer>
      </EmbedImageGridContainer>
    );
  }

  return (
    <EmbedImageGridContainer>
      {images.map((image, i) => {
        return (
          <EmbedImageContainer key={i}>
            <EmbedImage
              src={image.src}
              alt={image.alt}
              style={{ aspectRatio: 1 }}
            />
          </EmbedImageContainer>
        );
      })}{' '}
    </EmbedImageGridContainer>
  );
};

const EmbedImageGridContainer = styled('div', {
  base: {
    display: 'grid',
    gridTemplateColumns: '2',
    border: '1px solid',
    borderColor: 'gray.200',
    overflow: 'hidden',
    rounded: 'innerBox',
  },
});

const EmbedImageContainer = styled('div', {
  base: {
    p: 'box',
    rounded: 'innerBox',
    overflow: 'hidden',
    bg: 'white-pane.75',
  },
});

const EmbedImage = styled('img', {
  base: {
    width: '100%',
    maxHeight: '[20rem]',
    objectFit: 'contain',
    objectPosition: 'center',
    display: 'block',
  },
});
