import { MessageSquare } from 'lucide-react';
import { FC } from 'react';
import { InheritSpan } from '~src/components/text/spans';
import { css } from '~src/styled-system/css';
import { PostActionAnchor } from './common';

export const ReplyButton: FC<{
  onReply: (() => Promise<void>) | undefined;
  replyCount: number | null;
  replyHref: string | undefined;
  replyTarget?: string;
}> = ({ onReply, replyCount, replyTarget, replyHref }) => {
  return (
    <PostActionAnchor href={replyHref} onClick={onReply} target={replyTarget}>
      <MessageSquare
        className={css({
          width: 'icon.eighteen',
          height: 'icon.eighteen',
        })}
      />
      {replyCount !== null ? <InheritSpan>{replyCount}</InheritSpan> : null}
    </PostActionAnchor>
  );
};
