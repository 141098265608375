import { HIRAETH_ART_RATIO } from '@hiraeth/art';
import Link from 'next/link';
import type { FC } from 'react';
import { match } from 'ts-pattern';
import { BaseA } from '~src/components/anchor';
import { ICONS_BY_CHAIN_IDS } from '~src/components/chains/icons';
import { BaseLoadingImg } from '~src/components/img';
import { LightBox, type LightBoxProps } from '~src/components/light-box';
import { MonoP } from '~src/components/text';
import { useHiraethArtImageUrlByChainAndTxnHash } from '~src/pages/hiraeth/txn/hooks/useHiraethArtImageUrl';
import { useHiraethInTimeCheckoutContext } from '~src/pages/hiraeth/txn/providers/checkout/HiraethInTimeCheckout';
import type { BlockverseObjId } from '~src/shared-apps/blockverse/types';
import { CheckoutItemPostActions } from '~src/shared-apps/cart/component/item-post';
import type { CheckoutItemStateProps } from '~src/shared-apps/checkout/types/state';
import { css } from '~src/styled-system/css';
import { Circle, Flex, HStack } from '~src/styled-system/jsx';
import { getShortenedTxn } from '~src/utils/bytes/shorten';
import { getChainNetwork } from '~src/utils/chain';

export type SocialPostCustomFramesProps = {
  checkoutState?: CheckoutItemStateProps;
  customActionRow?: React.ReactNode;
  imageProps?: {
    priority?: boolean;
    defaultLightBoxRendering?: LightBoxProps['defaultRendering'];
  };
};

type CompleteSocialPostCustomFramesProps = {
  blockverseObjId: BlockverseObjId;
  checkoutState?: CheckoutItemStateProps;
  customActionRow?: React.ReactNode;
  imageProps?: SocialPostCustomFramesProps['imageProps'];
};

export const SocialPostCustomFrames: FC<
  CompleteSocialPostCustomFramesProps
> = ({ blockverseObjId, checkoutState, customActionRow, imageProps }) => {
  const projectId = blockverseObjId[0];

  return match(projectId)
    .with('hiraeth', () => {
      return (
        <HiraethObjFrame
          blockverseObjId={blockverseObjId}
          checkoutState={checkoutState}
          customActionRow={customActionRow}
          imageProps={imageProps}
        />
      );
    })
    .otherwise(() => null);
};

const HiraethObjFrame: FC<
  SocialPostCustomFramesProps & {
    blockverseObjId: BlockverseObjId<'hiraeth'>;
    imageProps?: SocialPostCustomFramesProps['imageProps'];
  }
> = ({ blockverseObjId, checkoutState, customActionRow, imageProps }) => {
  const [, chainId, txnHash] = blockverseObjId;
  const artUrl = useHiraethArtImageUrlByChainAndTxnHash(chainId, txnHash);
  return (
    <>
      <Flex
        bg={'darken.015'}
        flx={'flexColumnCenter'}
        p={'largeBox'}
        aspectRatio={'1:1'}
      >
        <LightBox
          contentRatio={HIRAETH_ART_RATIO}
          css={css.raw({
            pos: 'relative',
          })}
          defaultRendering={imageProps?.defaultLightBoxRendering}
        >
          <Link
            passHref
            legacyBehavior
            href={{
              pathname: '/hiraeth/[chainNetwork]/[txnHash]',
              query: {
                chainNetwork: getChainNetwork(chainId),
                txnHash,
              },
            }}
          >
            <BaseA
              display={'block'}
              pos={'absolute'}
              width={'100%'}
              height={'100%'}
            >
              {!!artUrl && (
                <BaseLoadingImg
                  className={css({
                    imageRendering: 'pixelated',
                    objectFit: 'contain',
                    display: 'block',
                    rounded: 'innerBox',
                    shadow: 'strong',
                  })}
                  priority={imageProps?.priority}
                  unoptimized
                  fill
                  alt={`Hiraeth art for ${txnHash}`}
                  // onError={(e) => {
                  //   e.currentTarget.src = getBase64PlaceholderImage(
                  //     1,
                  //     1,
                  //     'transparent',
                  //   );
                  // }}
                  src={artUrl}
                />
              )}
            </BaseA>
          </Link>
        </LightBox>
      </Flex>
      <HiraethFrameActionRow
        blockverseObjId={blockverseObjId}
        checkoutState={checkoutState}
        customActionRow={customActionRow}
      />
    </>
  );
};

const HiraethFrameActionRow: FC<
  SocialPostCustomFramesProps & {
    blockverseObjId: BlockverseObjId<'hiraeth'>;
  }
> = ({ blockverseObjId, checkoutState, customActionRow }) => {
  const [, chainId, txnHash] = blockverseObjId;
  const { setBlockverseObjId } = useHiraethInTimeCheckoutContext();

  if (customActionRow !== undefined) {
    return customActionRow;
  }

  return (
    <Flex flx={'flexRowEnds'} p={'innerBox'}>
      <HStack gap={'threeQuartersIncrement'}>
        <Circle
          size={'icon.fourteen'}
          bg={'gray.400'}
          overflow={'hidden'}
          pos={'relative'}
        >
          {ICONS_BY_CHAIN_IDS[chainId]}
        </Circle>
        <MonoP.Default color={'gray.400'}>
          {getShortenedTxn(txnHash)}
        </MonoP.Default>
      </HStack>
      <CheckoutItemPostActions
        itemState={checkoutState}
        objId={blockverseObjId}
        prepareCheckoutOnOpen={() => {
          setBlockverseObjId(blockverseObjId);
        }}
      />
    </Flex>
  );
};
