// import {
//   DEFAULT_FAMILY_STYLE,
//   DISPLAY_FONT_FAMILY_STYLE,
// } from '../../../../src/components/text';

import { css } from '~src/styled-system/css';
import { styled, type StyledComponent } from '~src/styled-system/jsx';
import type { TiptapEditorType } from '../provider/TiptapProvider';

export const postTextStylingCss = css.raw({
  'minWidth': '0',
  '& .tiptap': {
    'outline': 'none',
    'fontFamily': 'display',
    'fontWeight': 'display.normal',
    'fontSize': 'p.display',
    'overflow': 'hidden',
    'wordWrap': 'break-word',
    'minWidth': '0',
    '& p': {
      lineHeight: '[1.4]',
      overflow: 'hidden',
      color: 'gray.800',
      wordWrap: 'break-word',
      whiteSpace: 'pre-line',
    },
    '& strong': {
      fontWeight: 'display.bold',
    },
    '& a': {
      fontWeight: 'display.normal',
      color: 'url',
      cursor: 'pointer',
      textDecoration: 'none',
    },
  },
});

export const PostTextStylingContainer = styled('div', {
  base: postTextStylingCss,
});

export const postTextEditingStylingCss = css.raw({
  '& .tiptap': {
    minHeight: '[8rem]',
    color: 'black',
  },
});

export const PostTextEditingStylingContainer = styled('div', {
  base: css.raw(postTextStylingCss, postTextEditingStylingCss, {
    '& p.is-empty:nth-child(1)::before': {
      color: 'gray.400',
      content: 'attr(data-placeholder)',
      float: 'start',
      height: '0',
      pointerEvents: 'none',
    },
  }),
});

export const postWithTitleStylingCss = css.raw({
  '& h1': {
    fontSize: 'h4',
    fontWeight: 'display.bold',
    color: 'gray.800',
    mb: 'increment',
  },
});

export const PostWithTitleTextEditingStylingContainer = styled('div', {
  base: css.raw(
    postTextStylingCss,
    postTextEditingStylingCss,
    postWithTitleStylingCss,
    {
      '& h1.is-empty:nth-child(1)::before': {
        color: 'gray.400',
        content: 'attr(data-placeholder)',
        float: 'start',
        height: '0',
        pointerEvents: 'none',
      },
      '& p.is-empty:nth-child(2)::before': {
        color: 'gray.400',
        content: 'attr(data-placeholder)',
        float: 'start',
        height: '0',
        pointerEvents: 'none',
      },
    },
  ),
});

export const POST_EDITING_STYLING_MAP = {
  'simple': PostTextEditingStylingContainer,
  'with-title': PostWithTitleTextEditingStylingContainer,
} as const satisfies Record<TiptapEditorType, StyledComponent<any, any>>;
