import { Bookmark } from 'lucide-react';
import { FC } from 'react';
import { css } from '~src/styled-system/css';
import { PostActionButton } from './common';

export const BookmarkButton: FC<{
  onBookmark: (() => Promise<void>) | undefined;
  hasViewerBookmarked: boolean | undefined;
}> = ({ onBookmark, hasViewerBookmarked }) => {
  return (
    <PostActionButton
      color={hasViewerBookmarked ? 'coffee.strong' : 'gray.300'}
      onClick={onBookmark}
    >
      <Bookmark
        className={css({
          width: 'icon.eighteen',
          height: 'icon.eighteen',
          display: 'block',
          fill: hasViewerBookmarked ? 'coffee/30' : 'transparent',
        })}
      />
    </PostActionButton>
  );
};
