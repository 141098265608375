import { z } from 'zod';

export interface FarcasterAllowedChannel {
  name: string;
  slug: string;
  id: string;
}

export const farcasterAllowedChannelsZod = z.literal('crypto-history');

export type FarcasterAllowedChannelId = z.infer<
  typeof farcasterAllowedChannelsZod
>;

export const FARCASTER_ALLOWED_CHANNELS = {
  'crypto-history': {
    name: 'Crypto History',
    slug: '/crypto-history',
    id: 'crypto-history',
  },
} satisfies Record<FarcasterAllowedChannelId, FarcasterAllowedChannel>;

export const DEFAULT_FARCASTER_ALLOWED_CHANNEL_ID: FarcasterAllowedChannelId =
  'crypto-history';
