// import { Profile } from '@lens-protocol/react-web';
// import { FC } from 'react';
// import { LayeredBox } from '../../../../src/components/box/layered';
// import { ButtonRow } from '../../../../src/components/button';
// import { DisplayP } from '../../../../src/components/text';
// import { useBestLensHandle } from '../../../../src/external/lens/hooks/useBestLensHandle';
// import { SOFT_HOVER_STYLES } from '../../../../src/styles/defaultStyles';

import type { FC } from 'react';
import { SimpleImg } from '~src/components/img';
import { Circle } from '~src/styled-system/jsx';
import type { SystemStyleObject } from '~src/styled-system/types';

export type BaseProfileStylingProps = {
  size?: number | string;
  css?: SystemStyleObject;
};

export const BaseProfilePicture: FC<
  BaseProfileStylingProps & {
    src?: string;
  }
> = ({ size = 24, src, css }) => {
  return (
    <Circle
      size={'icon.twentyFour'}
      overflow={'hidden'}
      pos={'relative'}
      bg={'gray.100'}
      css={css as any}
      style={{
        width: size,
        height: size,
      }}
    >
      <SimpleImg src={src} />
    </Circle>
  );
};
