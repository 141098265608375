import { FC } from 'react';
import { trpc } from '~src/clients/trpc-client';
import { QUERY_LIVENESS } from '~src/constants/query';
import { useFarcasterUserFromPrivy } from '~src/hooks/privy/useFarcasterUserFromPrivy';
import type { SocialFarcasterEmbedPostForUI } from '~src/shared-apps/social/types/post/ui';
import { SocialEmbedPost } from './post';

export const SocialEmbedFarcaster: FC<SocialFarcasterEmbedPostForUI> = ({
  hash,
}) => {
  const user = useFarcasterUserFromPrivy();
  const castRes = trpc.social.post.getOne.useQuery(
    { hash, viewerFid: user?.fid ?? undefined },
    QUERY_LIVENESS.constant,
  );
  if (!castRes.data) {
    return null;
  }
  return <SocialEmbedPost {...castRes.data.post} type={'post'} />;
};
