import { baseButtonCss } from '~src/components/button';
import { css } from '~src/styled-system/css';
import { styled } from '~src/styled-system/jsx';

const postActionButtonCss = css.raw(baseButtonCss, {
  'color': 'gray.300',
  'display': 'flex',
  'alignItems': 'center',
  // 'height': 'squareIcon',
  'gap': 'halfIncrement',
  'textDecoration': 'none',
  'pos': 'relative',
  '& > svg': {
    tran: 'default',
    transitionProperty: 'transform opacity',
    flexShrink: 0,
    // transition: 'transform 0.2s ease-in-out',
    // marginRight: 0,
  },
  // gap: 'halfIncrement',
  '_hover': {
    '& > svg': {
      transform: 'scale(1.05)',
      opacity: '60',
    },
  },
});

export const PostActionButton = styled('button', {
  base: postActionButtonCss,
});

export const PostActionAnchor = styled('a', {
  base: postActionButtonCss,
});
