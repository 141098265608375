import { MoreHorizontal } from 'lucide-react';
import { FC, useState } from 'react';
import { Spinner } from 'react-activity';
import { match } from 'ts-pattern';
import { BaseA } from '~src/components/anchor';
import { LayeredBox } from '~src/components/box/layered';
import { SquareIconButton } from '~src/components/button';
import {
  CompactDropdownItemA,
  CompactDropdownItemButton,
  DropdownItemGroup,
  DropdownItemGroupGrid,
  DropdownLineSeparator,
} from '~src/components/dropdown';
import { Spacer } from '~src/components/layout';
import { Popover } from '~src/components/popover';
import { DisplayP } from '~src/components/text';
import { InheritSpan } from '~src/components/text/spans';
import { getFarcasterChannelUrl } from '~src/external/farcaster/utils/urls';
import { Box, Flex, styled } from '~src/styled-system/jsx';
import { token } from '~src/styled-system/tokens';
import type { SystemStyleObject } from '~src/styled-system/types';
import { sizes } from '~src/theme/tokens/sizes';
import type { SocialPostForUI } from '../../types/post/ui';
import { BaseProfilePicture } from '../profile/pfp/base';
import { BookmarkButton } from './actions/BookmarkButton';
import { LikeButton } from './actions/LikeButton';
import { ReplyButton } from './actions/ReplyButton';
import { RepostButton } from './actions/RepostButton';
import { TimeAgoSpan } from './common';
import { SocialEmbedsFeed } from './embed';
import {
  SocialPostCustomFrames,
  type SocialPostCustomFramesProps,
} from './frame';
import { PostTextStylingContainer } from '../../../tiptap/components/styling';

export type SocialPostCardMutations = {
  onDelete: (() => Promise<void>) | undefined;

  onLike: (() => Promise<void>) | undefined;

  onReply: (() => Promise<void>) | undefined;
  replyHref: string | undefined;
  replyTarget?: string;

  onRepost: (() => Promise<void>) | undefined;
  repostHref: string | undefined;
  repostTarget?: string;

  onBookmark: (() => Promise<void>) | undefined;
};

export type SocialPostCardProps = SocialPostCustomFramesProps & {
  post: SocialPostForUI;
  state: 'optimistic' | 'error' | 'posted' | 'deleting';
  slots?: Partial<{
    container: SystemStyleObject;
    actionRow: SystemStyleObject;
    blockverseFrameContainer: SystemStyleObject;
  }>;
  showBlockverseFrame?: boolean;
} & SocialPostCardMutations;

const PROFILE_PICTURE_SIZE_KEY = 'mediumPfp' as const;
const PROFILE_PICTURE_SIZE = sizes[PROFILE_PICTURE_SIZE_KEY].value;

export const SocialPostCard: FC<SocialPostCardProps> = ({
  state,
  post,
  onDelete,
  onLike,
  onReply,
  replyTarget,
  replyHref,
  onRepost,
  repostTarget,
  repostHref,
  onBookmark,
  showBlockverseFrame,
  slots = {},
  checkoutState,
  customActionRow,
}) => {
  const {
    author,
    channel,
    html,
    timestamp,
    externalUrl,
    externalArchivedUrl,
    reactions,
    viewer,
    embeds,
  } = post;
  return (
    <LayeredBox
      p={'innerBox'}
      bg={'white'}
      pos={'relative'}
      css={slots?.container}
    >
      <Box display={'flex'} alignItems={'flex-start'} gap={'increment'}>
        <Box flexGrow={1}>
          <Flex flx={'flexRowEnds'} height={'mediumPfp'}>
            <Flex gap={'increment'}>
              <BaseProfilePicture
                size={PROFILE_PICTURE_SIZE}
                src={author.profileImageSrc ?? undefined}
                css={{
                  border: '1px solid',
                  borderColor: 'gray.200',
                }}
              />
              <Box>
                <DisplayP.Caption
                  color={'gray.600'}
                  fontWeight={'display.bold'}
                >
                  <BaseA
                    display={'block'}
                    textDecoration={'none'}
                    target={'_blank'}
                    href={author.profileUrl ?? '#'}
                  >
                    {author.name ?? '-'}
                  </BaseA>
                </DisplayP.Caption>
                <DisplayP.Caption color={'gray.400'} mt={'2'}>
                  @{author.handle ?? '-'}
                  {!!timestamp && (
                    <>
                      {' '}
                      <InheritSpan color={'gray.400'}> &middot; </InheritSpan>
                      <TimeAgoSpan timestamp={timestamp} />
                    </>
                  )}
                  {!!channel && (
                    <InheritSpan color={'gray.300'}>
                      {' '}
                      in{' '}
                      <BaseA
                        href={getFarcasterChannelUrl(channel.id)}
                        target={'_blank'}
                      >
                        /{channel.id}
                      </BaseA>
                    </InheritSpan>
                  )}
                </DisplayP.Caption>
              </Box>
            </Flex>
            <MoreActionsDropdown
              state={state}
              isAuthor={viewer?.isAuthor ?? false}
              onDelete={onDelete}
              externalUrl={externalUrl}
              externalArchivedUrl={externalArchivedUrl}
            />
          </Flex>
          <Spacer.Box />
          {!!html && (
            <PostTextStylingContainer>
              <div
                className="tiptap"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </PostTextStylingContainer>
          )}
          {!!embeds && !!embeds.length && (
            <>
              <Spacer.Box />
              <SocialEmbedsFeed embeds={embeds} />
            </>
          )}
          {match(state)
            .with('deleting', () => (
              <Flex
                mt={'box'}
                flx={'flexRowEnds'}
                py={'1'}
                css={slots?.actionRow}
              >
                <BottomBarActionsContainer gap={'increment'}>
                  <Spinner size={10} color={token.var('colors.gray.400')} />
                  <DisplayP.Caption
                    color={'gray.400'}
                    animation={'lightly-breath'}
                    transform={'translateY(-1px)'}
                  >
                    Deleting...
                  </DisplayP.Caption>
                </BottomBarActionsContainer>
                <BottomBarActionsContainer
                  gap={'increment'}
                ></BottomBarActionsContainer>
              </Flex>
            ))
            .with(
              'optimistic',
              () => null,
              // <ButtonRow $paddingType="increment">
              //   <StateContainer>
              //     <Spinner size={10} color={theme.gray.600} />
              //   </StateContainer>
              //   <DisplayMedP color={'gray.400'}>Posting...</DisplayMedP>
              // </ButtonRow>
            )
            .with(
              'error',
              () => null,
              // <ButtonRow $paddingType="increment">
              //   <StateContainer>
              //     <StyledCloseIcon />
              //   </StateContainer>
              //   <DisplayMedP color={'error.strong'}>Error posting</DisplayMedP>
              // </ButtonRow>
            )
            .otherwise(() => (
              <Flex mt={'box'} flx={'flexRowEnds'} css={slots?.actionRow}>
                <BottomBarActionsContainer>
                  {(!!onReply || !!replyHref) && (
                    <ReplyButton
                      onReply={onReply}
                      replyCount={reactions.replyCount}
                      replyHref={replyHref}
                      replyTarget={replyTarget}
                    />
                  )}
                  {(!!onRepost || !!repostHref) && (
                    <RepostButton
                      onRepost={onRepost}
                      repostCount={reactions.repostCount}
                      repostHref={repostHref}
                      repostTarget={repostTarget}
                      hasViewerReposted={viewer?.hasReposted}
                    />
                  )}
                  {!!onLike && (
                    <LikeButton
                      onLike={onLike}
                      likeCount={reactions.likeCount}
                      hasViewerLiked={viewer?.hasLiked}
                    />
                  )}
                </BottomBarActionsContainer>
                <BottomBarActionsContainer gap={'increment'}>
                  {!!onBookmark && (
                    <BookmarkButton
                      onBookmark={onBookmark}
                      hasViewerBookmarked={viewer?.hasBookmarked}
                    />
                  )}
                </BottomBarActionsContainer>
              </Flex>
            ))}
        </Box>
      </Box>
      {!!showBlockverseFrame && !!post.blockverse?.id && (
        <Box
          mx={'-innerBox'}
          mb={'-innerBox'}
          mt={'innerBox'}
          css={slots?.blockverseFrameContainer}
        >
          <SocialPostCustomFrames
            blockverseObjId={post.blockverse.id}
            checkoutState={checkoutState}
            customActionRow={customActionRow}
          />
        </Box>
      )}
    </LayeredBox>
  );
};

const BottomBarActionsContainer = styled('div', {
  base: {
    display: 'flex',
    alignItems: 'center',
    // height: 'squareIcon',
    gap: 'innerBox',
  },
});

const MoreActionsDropdown: FC<{
  isAuthor: boolean;
  state: SocialPostCardProps['state'];
  externalUrl: SocialPostForUI['externalUrl'];
  externalArchivedUrl: SocialPostForUI['externalArchivedUrl'];
  onDelete: SocialPostCardProps['onDelete'];
}> = ({ isAuthor, externalUrl, externalArchivedUrl, state, onDelete }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Popover.Root
      positioning={{ placement: 'bottom-end' }}
      onOpenChange={(e) => {
        setIsOpen(e.open);
      }}
      open={isOpen}
    >
      <Popover.Trigger asChild>
        <SquareIconButton
          bg={'transparent'}
          color={'gray.400'}
          _open={{
            bg: 'darken.025',
          }}
        >
          <MoreHorizontal />
        </SquareIconButton>
      </Popover.Trigger>
      <Popover.Positioner style={{ minWidth: '120px' }}>
        <Popover.Content>
          <DropdownItemGroupGrid>
            <DropdownItemGroup>
              {externalUrl && (
                <CompactDropdownItemA
                  key={externalUrl.label}
                  href={externalUrl.url}
                  target="_blank"
                >
                  {externalUrl.label}
                </CompactDropdownItemA>
              )}
              {externalArchivedUrl && (
                <CompactDropdownItemA
                  key={externalArchivedUrl.label}
                  href={externalArchivedUrl.url}
                  target="_blank"
                >
                  {externalArchivedUrl.label}
                </CompactDropdownItemA>
              )}
              {isAuthor && !!onDelete && (
                <>
                  <DropdownLineSeparator />
                  <DeleteDropdownItemButton
                    onDelete={onDelete}
                    closePopover={() => setIsOpen(false)}
                  />
                </>
              )}
            </DropdownItemGroup>
          </DropdownItemGroupGrid>
        </Popover.Content>
      </Popover.Positioner>
    </Popover.Root>
  );
};

const DeleteDropdownItemButton: FC<{
  onDelete: SocialPostCardProps['onDelete'];
  closePopover: () => void;
}> = ({ onDelete, closePopover }) => {
  // const hideTask = useHidePublication();
  return (
    <CompactDropdownItemButton
      // disabled={hideTask.loading}
      onClick={() => {
        onDelete?.();
        closePopover();
      }}
      color={'error.strong'}
    >
      Delete
    </CompactDropdownItemButton>
  );
};

// const RepostButton: FC<{
//   onRepost: (() => Promise<void>) | undefined;
//   repostCount: number | null;
//   repostHref: string | undefined;
//   repostTarget?: string;
//   hasViewerReposted: boolean | undefined;
// }> = ({
//   onRepost,
//   repostCount,
//   repostTarget,
//   repostHref,
//   hasViewerReposted,
// }) => {
//   return (
//     <PostActionWithLabel
//       target={repostTarget}
//       as={'a'}
//       href={repostHref}
//       onClick={onRepost}
//     >
//       <StyledStatefulRepostIcon $hasViewerReposted={hasViewerReposted} />
//       {repostCount !== null ? <Label>{repostCount}</Label> : null}
//     </PostActionWithLabel>
//   );
// };

// const StyledStatefulRepostIcon = styled(StatefulRepostIcon)<{
//   $hasViewerReposted: boolean | undefined;
// }>`
//   height: 26px;
//   width: 26px;
//   * {
//     stroke: ${(p) =>
//       p.$hasViewerReposted ? p.theme['lavender.strong'] : p.theme.gray.400};
//   }
//   #background {
//     stroke: none;
//     fill: ${(p) =>
//       p.$hasViewerReposted ? p.theme.lavender : 'transparent'};
//     fill-opacity: 1;
//   }
// `;
