import React, { createContext, useContext, useMemo } from 'react';
import { CONTEXT_DEFAULT_FUNCTION } from '~src/constants/defaults';
import type { SocialPostForUI } from '../types/post/ui';

export type SocialFeedQueryModifiers<P extends {} = SocialPostForUI> = {
  cancelQueries: () => Promise<void>;
  updatePostOptimistically: (
    postId: string,
    modifier: (socialPost: P) => P,
  ) => void;
  invalidateFeed: () => void;
};

type SocialFeedQueryModifiersContextType = SocialFeedQueryModifiers & {};

export const SocialFeedQueryModifiersContext =
  createContext<SocialFeedQueryModifiersContextType>({
    cancelQueries: CONTEXT_DEFAULT_FUNCTION,
    updatePostOptimistically: CONTEXT_DEFAULT_FUNCTION,
    invalidateFeed: CONTEXT_DEFAULT_FUNCTION,
  });

export type SocialFeedQueryModifiersProviderProps =
  SocialFeedQueryModifiers & {};

export const useSocialFeedQueryModifiersContext = () => {
  return useContext(SocialFeedQueryModifiersContext);
};

export const SocialFeedQueryModifiersProvider = ({
  children,
  ...modifiers
}: SocialFeedQueryModifiersProviderProps & { children: React.ReactNode }) => {
  const state = useMemo(() => {
    return {
      ...modifiers,
    };
  }, [modifiers]);

  return (
    <SocialFeedQueryModifiersContext.Provider value={state}>
      {children}
    </SocialFeedQueryModifiersContext.Provider>
  );
};
