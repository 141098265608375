import { type CastId } from '@neynar/nodejs-sdk/build/neynar-api/v2';
import { usePrivy } from '@privy-io/react-auth';
import { useMutation } from '@tanstack/react-query';
import { trpc } from '~src/clients/trpc-client';
import { useFarcasterClient } from '~src/external/farcaster/hooks/useFarcasterClient';
import { useFarcasterSignerFromPrivy } from '~src/external/farcaster/hooks/useFarcasterSignerFromPrivy';
import {
  useSocialFeedQueryModifiersContext,
  type SocialFeedQueryModifiers,
} from '../../providers/SocialFeedQueryModifiers';
import type { SocialPostForUI } from '../../types/post/ui';

export const useDeletePostMutation = (modifiers?: SocialFeedQueryModifiers) => {
  const farcasterClient = useFarcasterClient();
  const farcasterSigner = useFarcasterSignerFromPrivy();
  const { user } = usePrivy();

  const modifiersFromContext = useSocialFeedQueryModifiersContext();

  const modifiersToUse = modifiers || modifiersFromContext;

  const { mutateAsync: deletePost } = trpc.social.post.delete.useMutation();

  return useMutation({
    mutationFn: async ({ post }: { post: SocialPostForUI }) => {
      if (!user?.farcaster?.signerPublicKey || !user.farcaster.fid) {
        throw new Error('User not connected to Farcaster');
      }

      if (!post.platform) {
        throw new Error('Post is not from Farcaster');
      }

      //cast post to farcaster here
      const target: CastId = {
        fid: Number(post.platform.authorId),
        hash: post.platform.id,
      };

      const submitCastResponse = await farcasterClient.removeCast(
        target.hash,
        target.fid,
        farcasterSigner,
      );

      // be it this is not atomic, this may not trigger
      await deletePost({ id: post.id });

      return {
        hash: submitCastResponse.hash,
        postId: post.id,
      };
    },
    // onMutate: async ({ post, reactionType }) => {
    //   const hasReacted =
    //     reactionType === ReactionType.Like
    //       ? post.viewer?.hasLiked
    //       : post.viewer?.hasReposted;
    //   await modifiersToUse.cancelQueries();
    //   modifiersToUse.updatePostOptimistically(post.id, (post) =>
    //     produce(post, (draft) => {
    //       if (reactionType === ReactionType.Like) {
    //         draft.viewer!.hasLiked = !hasReacted;
    //         draft.reactions.likeCount += !hasReacted ? 1 : -1;
    //       } else {
    //         draft.viewer!.hasReposted = !hasReacted;
    //         draft.reactions.repostCount += !hasReacted ? 1 : -1;
    //       }
    //     }),
    //   );
    // },
    onSuccess: () => {
      modifiersToUse.invalidateFeed();
      // modifiersToUse.settledCastMutation(getMutationKey(trpc.farcaster.reactCast));
    },
    // onError: (error, { post, reactionType }) => {
    //   console.log(error)
    //   const hasReacted =
    //     reactionType === ReactionType.Like
    //       ? post.viewer?.hasLiked
    //       : post.viewer?.hasReposted;
    //   modifiersToUse.updatePostOptimistically(post.id, (post) =>
    //     produce(post, (draft) => {
    //       if (reactionType === ReactionType.Like) {
    //         draft.viewer!.hasLiked = !!hasReacted;
    //         draft.reactions.likeCount += !hasReacted ? -1 : 1;
    //       } else {
    //         draft.viewer!.hasReposted = !!hasReacted;
    //         draft.reactions.repostCount += !hasReacted ? -1 : 1;
    //       }
    //     }),
    //   );
    // },
  });
};
