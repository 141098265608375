import {
  ReactionType,
  type CastId,
} from '@neynar/nodejs-sdk/build/neynar-api/v2';
import { usePrivy } from '@privy-io/react-auth';
import { useMutation } from '@tanstack/react-query';
import { produce } from 'immer';
import { useFarcasterClient } from '~src/external/farcaster/hooks/useFarcasterClient';
import { useFarcasterSignerFromPrivy } from '~src/external/farcaster/hooks/useFarcasterSignerFromPrivy';
import {
  useSocialFeedQueryModifiersContext,
  type SocialFeedQueryModifiers,
} from '../../../shared-apps/social/providers/SocialFeedQueryModifiers';
import type { SocialPostForUI } from '../../../shared-apps/social/types/post/ui';

export const useFarcasterReactionMutation = (
  modifiers?: SocialFeedQueryModifiers,
) => {
  const farcasterClient = useFarcasterClient();
  const farcasterSigner = useFarcasterSignerFromPrivy();
  const { user } = usePrivy();

  const modifiersFromContext = useSocialFeedQueryModifiersContext();

  const modifiersToUse = modifiers || modifiersFromContext;

  return useMutation({
    mutationFn: async ({
      post,
      reactionType,
    }: {
      post: SocialPostForUI;
      reactionType: ReactionType;
    }) => {
      if (!user?.farcaster?.signerPublicKey || !user.farcaster.fid) {
        throw new Error('User not connected to Farcaster');
      }

      if (!post.platform) {
        throw new Error('Post is not from Farcaster');
      }

      const hasReacted =
        reactionType === ReactionType.Like
          ? post.viewer?.hasLiked
          : post.viewer?.hasReposted;

      //cast post to farcaster here
      const target: CastId = {
        fid: Number(post.platform.authorId),
        hash: post.platform.id,
      };

      const submitCastResponse = await (hasReacted
        ? farcasterClient.removeReaction(
            {
              type: reactionType,
              target,
            },
            user.farcaster.fid,
            farcasterSigner,
          )
        : farcasterClient.submitReaction(
            {
              type: reactionType,
              target,
            },
            user.farcaster.fid,
            farcasterSigner,
          ));

      return {
        hash: submitCastResponse.hash,
        newReactionState: !hasReacted,
        reactionType,
      };
    },
    onMutate: async ({ post, reactionType }) => {
      const hasReacted =
        reactionType === ReactionType.Like
          ? post.viewer?.hasLiked
          : post.viewer?.hasReposted;
      await modifiersToUse.cancelQueries();
      modifiersToUse.updatePostOptimistically(post.id, (post) =>
        produce(post, (draft) => {
          if (reactionType === ReactionType.Like) {
            draft.viewer!.hasLiked = !hasReacted;
            draft.reactions.likeCount += !hasReacted ? 1 : -1;
          } else {
            draft.viewer!.hasReposted = !hasReacted;
            draft.reactions.repostCount += !hasReacted ? 1 : -1;
          }
        }),
      );
    },
    onSuccess: () => {
      // modifiersToUse.settledCastMutation(getMutationKey(trpc.farcaster.reactCast));
    },
    onError: (error, { post, reactionType }) => {
      console.log(error);
      const hasReacted =
        reactionType === ReactionType.Like
          ? post.viewer?.hasLiked
          : post.viewer?.hasReposted;
      modifiersToUse.updatePostOptimistically(post.id, (post) =>
        produce(post, (draft) => {
          if (reactionType === ReactionType.Like) {
            draft.viewer!.hasLiked = !!hasReacted;
            draft.reactions.likeCount += !hasReacted ? -1 : 1;
          } else {
            draft.viewer!.hasReposted = !!hasReacted;
            draft.reactions.repostCount += !hasReacted ? -1 : 1;
          }
        }),
      );
    },
  });
};
