// import { FC } from 'react';
// import { SocialEmbed, SocialEmbedPost } from '../../../../../src/shared-apps/social/types';
// import { LayeredBox } from '../../../../../src/components/box/layered';
// import { UpRightArrowIcon } from '../../../../../src/components/icon/arrows';
// import { ColorSpan, DisplayMedP, DisplayP } from '../../../../../src/components/text';
// import {
//   SOFT_HOVER_STYLES,
//   SVG_ONLY_FILL_COLOR,
// } from '../../../../../src/styles/defaultStyles';
// import { BaseA } from '../../../../../src/components/anchor';
// import { ButtonRow } from '../../../../../src/components/button';
// import { FlexEnds } from '../../../../../src/components/flex';
// import state from '../../../../../pages/api/hiraeth/[chainNetwork]/state';
// import { ProfilePicture } from '../../profile';
// import { useTimeAgo } from 'react-time-ago';
// import { EmbedsFeed } from '.';
// import { ExtraSmallContentSpacer, IncrementContentSpacer } from '../../../../../src/components/layout/content';
// import { getFarcasterChannelUrl } from '../../../../../src/external/farcaster/utils/urls';
// import { PostTextStylingContainer } from '../styling';
// import { TimeAgoSpan, ChannelSpan } from '../common';

import type { FC } from 'react';
import { UpRightArrowIcon } from '~src/components/icon/arrows';
import { Spacer } from '~src/components/layout';
import { DisplayP } from '~src/components/text';
import { InheritSpan } from '~src/components/text/spans';
import type {
  SocialEmbedForUI,
  SocialEmbedPostForUI,
} from '~src/shared-apps/social/types/post/ui';
import { css } from '~src/styled-system/css';
import { Flex, styled } from '~src/styled-system/jsx';
import { SocialEmbedsFeed } from '.';
import { BaseProfilePicture } from '../../profile/pfp/base';
import { TimeAgoSpan } from '../common';
import { PostTextStylingContainer } from '../../../../tiptap/components/styling';

const ALLOWED_RENDERED_EMBEDS: SocialEmbedForUI['type'][] = ['image', 'video'];

export const SocialEmbedPost: FC<SocialEmbedPostForUI> = ({
  author,
  // externalLinks,
  timestamp,
  html,
  embeds,
  channel,
  externalUrl,
}) => {
  const filteredEmbeds = embeds?.filter((embed) =>
    ALLOWED_RENDERED_EMBEDS.includes(embed.type),
  );

  return (
    <>
      <EmbedPostContainer
        href={externalUrl?.url ?? '#'}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Flex flx={'flexRowEnds'}>
          <Flex alignItems={'center'} gap="increment">
            <BaseProfilePicture
              src={author.profileImageSrc ?? undefined}
              size={20}
            />
            <DisplayP.Caption color={'gray.400'} lineHeight={'1'}>
              {/* <InheritSpan color={'black'}>
                {' '}
                {author.name ?? '-'}
              </InheritSpan> */}
              {/* &nbsp;&nbsp; */}
              <InheritSpan color={'gray.600'}>
                @{author.handle ?? '-'}
              </InheritSpan>
              &nbsp;&nbsp;
              {!!timestamp && <TimeAgoSpan timestamp={timestamp} />}
            </DisplayP.Caption>
          </Flex>
          <UpRightArrowIcon
            className={css({
              width: 'icon.eighteen',
              height: 'icon.eighteen',
              color: 'gray.400',
            })}
          />
        </Flex>
        <Spacer.Box />
        {!!html && (
          <PostTextStylingContainer>
            <div
              className="tiptap"
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </PostTextStylingContainer>
        )}
        <Spacer.Box />
        {!!filteredEmbeds && !!filteredEmbeds.length && (
          <>
            <SocialEmbedsFeed embeds={filteredEmbeds} />
            {/* <ExtraSmallContentSpacer /> */}
          </>
        )}
        {/* {!!channel?.id && (
        <DisplayMedP>
          <ChannelSpan
            href={getFarcasterChannelUrl(channel.id)}
            target={'_blank'}
            fgColor={'gray.600'}
            color={'gray.100'}
          >
            /{channel.id}
          </ChannelSpan>
        </DisplayMedP>
      )} */}
      </EmbedPostContainer>
    </>
  );
};

const EmbedPostContainer = styled('a', {
  base: {
    rounded: 'innerBox',
    background: 'white-pane',
    p: 'innerBox',
    border: '1px solid',
    borderColor: 'gray.200',
    textDecoration: 'none',
    pos: 'relative',
    minWidth: '0',
    display: 'block',
    gap: 'increment',
    overflow: 'hidden',
  },
});
