import { FC, useMemo } from 'react';

import { match } from 'ts-pattern';
import { EmbedImageGrid, type SocialEmbedImageGridProps } from './image';
// import { EmbedUrl } from './url';
// import { EmbedVideo } from './video';
// // import { EmbedPost } from './post';
// import { EmbedFarcasterFromHash } from './farcaster';
import type {
  SocialEmbedForUI,
  SocialEmbedImageForUI,
} from '~src/shared-apps/social/types/post/ui';
import { Box } from '~src/styled-system/jsx';
import { SocialEmbedFarcaster } from './farcaster';
import { EmbedUrl } from './url';
import { EmbedVideo } from './video';

export type SocialEmbedContentCardsInPost =
  | SocialEmbedImageGridProps
  | Exclude<SocialEmbedForUI, SocialEmbedImageForUI>;

export const SocialEmbedsFeed: FC<{
  embeds: SocialEmbedForUI[];
}> = ({ embeds }) => {
  const embedCards = useMemo(() => {
    let embedCards: SocialEmbedContentCardsInPost[] = [];
    // pull out all images and group them into a grid
    const images = embeds.filter(
      (embed): embed is SocialEmbedImageForUI => embed.type === 'image',
    );
    if (images.length > 0) {
      embedCards.push({
        type: 'image-grid',
        images,
      });
    }
    // put rest of the embeds here
    const omittedEmbeds: SocialEmbedContentCardsInPost[] = embeds.filter(
      (embed) => embed.type !== 'image',
    ) as SocialEmbedContentCardsInPost[];
    embedCards.push(...omittedEmbeds);

    // filter out any urls with no ogImage or ogDescription
    embedCards = embedCards.filter((embed) => {
      if (embed.type !== 'url') {
        return true;
      }
      return !!embed.ogImage || !!embed.ogDescription;
    });
    return embedCards;
  }, [embeds]);

  return (
    <Box display={'grid'} gap={'innerBox'}>
      {embedCards.map((embed, i) => {
        return (
          match(embed)
            .with({ type: 'image-grid' }, (m) => {
              return <EmbedImageGrid key={i} {...m} />;
            })
            .with({ type: 'video' }, (m) => {
              return <EmbedVideo key={i} {...m} />;
            })
            .with({ type: 'url' }, (m) => {
              return <EmbedUrl key={i} {...m} />;
            })
            // .with({ type: 'post' }, (m) => {
            //   return <EmbedPost key={i} {...m} />;
            // })
            .with({ type: 'farcaster' }, (m) => {
              return <SocialEmbedFarcaster key={i} {...m} />;
            })
            .exhaustive()
        );
      })}
    </Box>
  );
};
