import { Repeat } from 'lucide-react';
import { FC } from 'react';
import { InheritSpan } from '~src/components/text/spans';
import { css } from '~src/styled-system/css';
import { PostActionAnchor } from './common';

export const RepostButton: FC<{
  onRepost: (() => Promise<void>) | undefined;
  repostCount: number | null;
  repostHref: string | undefined;
  repostTarget?: string;
  hasViewerReposted: boolean | undefined;
}> = ({
  onRepost,
  repostCount,
  repostTarget,
  repostHref,
  hasViewerReposted,
}) => {
  return (
    <PostActionAnchor
      href={repostHref}
      onClick={onRepost}
      color={hasViewerReposted ? 'lavender.strong' : 'gray.300'}
      target={repostTarget}
    >
      <Repeat
        className={css({
          width: 'icon.eighteen',
          height: 'icon.eighteen',
        })}
      />
      {repostCount !== null ? <InheritSpan>{repostCount}</InheritSpan> : null}
    </PostActionAnchor>
  );
};
