import {
  Cast,
  EmbedCastId,
  EmbedUrl,
} from '@neynar/nodejs-sdk/build/neynar-api/v2';
import { resolveURL } from 'ufo';
import { WARPCAST_PARENT_CHANNEL_BASE_URL } from '~src/constants';

export const getFarcasterProfileUrl = (handle: string) => {
  return `https://warpcast.com/${handle}`;
};

export const getFarcasterChannelUrl = (channelId: string) => {
  return resolveURL(WARPCAST_PARENT_CHANNEL_BASE_URL, channelId);
};

export const getFarcasterCastUrl = (cast: Cast) => {
  return resolveURL(
    getFarcasterProfileUrl(cast.author.username),
    cast.hash.slice(0, 10),
  );
};

export const isFarcasterEmbedUrl = (metadata: any): metadata is EmbedUrl => {
  return !!metadata.metadata;
};

export const isFarcasterEmbedCast = (
  metadata: any,
): metadata is EmbedCastId => {
  return !!metadata.cast_id;
};

export const getChannelIdFromParentUrl = (parentUrl: string) => {
  if (parentUrl.startsWith(WARPCAST_PARENT_CHANNEL_BASE_URL)) {
    const urlComponents = parentUrl.split('/');
    return urlComponents[urlComponents.length - 1];
  }
  return null;
};
