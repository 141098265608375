import { Heart } from 'lucide-react';
import { FC, useMemo } from 'react';
import { InheritSpan } from '~src/components/text/spans';
import { css } from '~src/styled-system/css';
import { PostActionButton } from './common';

export const LikeButton: FC<{
  onLike: () => Promise<void>;
  likeCount: number | null;
  hasViewerLiked: boolean | undefined;
}> = ({ onLike, likeCount, hasViewerLiked }) => {
  const status = useMemo(() => {
    if (hasViewerLiked) {
      return 'liked-by-you';
    }
    if (likeCount) {
      return 'liked';
    }
    return 'empty';
  }, [hasViewerLiked, likeCount]);

  return (
    <PostActionButton
      onClick={onLike}
      color={status === 'liked-by-you' ? 'blockverse' : 'gray.300'}
    >
      <Heart
        className={css({
          width: 'icon.eighteen',
          height: 'icon.eighteen',
          display: 'block',
          fill:
            status === 'liked-by-you' ? 'blockverse.weak/40' : 'transparent',
        })}
      />
      {likeCount !== null ? <InheritSpan>{likeCount}</InheritSpan> : null}
    </PostActionButton>
  );
};
