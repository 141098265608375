import { useFarcasterSigner } from '@privy-io/react-auth';
import { ExternalEd25519Signer } from '@standard-crypto/farcaster-js';
import { useMemo } from 'react';

export const useFarcasterSignerFromPrivy = () => {
  const { getFarcasterSignerPublicKey, signFarcasterMessage } =
    useFarcasterSigner();
  return useMemo(() => {
    return new ExternalEd25519Signer(
      signFarcasterMessage,
      getFarcasterSignerPublicKey,
    );
  }, [signFarcasterMessage, getFarcasterSignerPublicKey]);
};
