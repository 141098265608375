import { HubRestAPIClient } from '@standard-crypto/farcaster-js';
import { useMemo } from 'react';
import { route } from '~src/utils/routes';
import { useFarcasterSignerFromPrivy } from './useFarcasterSignerFromPrivy';

export const useFarcasterClient = () => {
  const signer = useFarcasterSignerFromPrivy();
  return useMemo(() => {
    return new HubRestAPIClient({
      hubUrl: route({
        pathname: '/api/proxy/farcaster',
      }),
    });
  }, [signer]);
};
