import { FC } from 'react';
import { LayeredBox } from '~src/components/box/layered';
import { DisplayP } from '~src/components/text';
import { SocialEmbedVideoForUI } from '~src/shared-apps/social/types/post/ui';

export const EmbedVideo: FC<SocialEmbedVideoForUI> = ({ src }) => {
  return (
    <>
      <LayeredBox
        bg="white-pane.75"
        border="1px solid"
        borderColor="gray.200"
        rounded="innerBox"
      >
        <DisplayP.Caption my={'largeBox'} textAlign="center" color={'gray.400'}>
          Video Support Coming Soon
        </DisplayP.Caption>
      </LayeredBox>
    </>
  );
};
